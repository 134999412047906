import React from 'react';
import { Card, CardContent, Typography, CardActionArea } from '@mui/material';

import './works.scss';

// Projects data structure
const projects = [
  {
    id: 'career-compass',
    title: 'Career Compass',
    description:
      'A career guidance platform that uses AI to quickly provide personalized career path suggestions.',
    link: 'https://careerlens.ca/questionnaire',
    image: 'https://utfs.io/f/VEFgVBTWbeCYlhK8Nlz0gkrC8BtXeTfMcYNF3mzpuG9PhVqK',
    tags: [
      { name: 'Remix', className: 'remix' },
      { name: 'TypeScript', className: 'reactJs' },
      { name: 'Supabase', className: 'supabase' },
    ],
  },
  {
    id: 'blens',
    title: 'Blens',
    description:
      'A platform for photographers and videographers providing them with a digital community and marketplace to enhance their online presence.',
    link: 'https://blens.ca',
    image: 'https://utfs.io/f/VEFgVBTWbeCYxE7Asm1B3Wc2gF9EHlNLpk4CmaPfvxnhbzRt',
    tags: [
      { name: 'Ruby on rails', className: 'ruby' },
      { name: 'React native', className: 'reactJs' },
    ],
  },
  {
    id: 'auction-zone',
    title: 'AuctionZone: E-Commerce Auction System',
    description:
      'Built a microservice-based auction platform, managing catalogues, auctions, user accounts, and payments.',
    link: 'https://eecs-4413-auction-website.vercel.app/',
    image: 'https://utfs.io/f/VEFgVBTWbeCYct08WX3nDdKsvZhaOlWF5qTBLm3PGfwjro68',
    tags: [
      { name: 'Java', className: 'java' },
      { name: 'Spring Boot', className: 'java' },
      { name: 'Next.js', className: 'reactJs' },
    ],
  },
  {
    id: 'kokon-app',
    title: 'KOkon.app',
    description: 'Built my web portfolio using React and SCSS',
    link: 'https://github.com/kingsley9/my-portfolio',
    image: 'https://utfs.io/f/VEFgVBTWbeCYi7LLaUhyFgbe2oOi5mYtq1NUVaxuHMrGcJ7Q',
    tags: [
      { name: 'React', className: 'reactJs' },
      { name: 'SCSS', className: 'cs' },
      { name: 'Firebase', className: 'firebase' },
    ],
  },
  {
    id: 'note-predict',
    title: 'NotePredict (Engineering Capstone)',
    description:
      'A web app that optimizes Jupyter notebook organization using deep learning.',
    link: 'https://cell-order-predictor-w3yw.vercel.app',
    image: 'https://utfs.io/f/VEFgVBTWbeCYEXLoqokMd3yiZDaWrRv857thCeSYx6n24cBw',
    tags: [
      { name: 'React', className: 'reactJs' },
      { name: 'Python', className: 'python' },
      { name: 'Flask', className: 'python' },
    ],
  },
  {
    id: 'help-desk',
    title: 'Help Desk (Cybersecurity capstone)',
    description:
      'Developed a help desk app as part of my cybersecurity capstone project and performed rigorous security and performance testing on it.',
    link: 'https://kingsley9.github.io/EECS4481-Project-T5/',
    image: 'https://utfs.io/f/VEFgVBTWbeCYdiBOx3jIG9fPJHMzL3rYWU8nl4wB7xNetu15',
    tags: [
      { name: 'React', className: 'reactJs' },
      { name: 'Typescript', className: 'reactJs' },
      { name: 'Express.js', className: 'javascript' },
    ],
  },
];

function ProjectCard({ project }) {
  return (
    <Card
      className="card"
      variant="elevation"
      elevation={2}
      sx={{
        width: 380,
        height: 340,
      }}
    >
      <CardActionArea
        href={project.link}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          height: '100%',
        }}
      >
        <img
          src={project.image}
          alt={project.title}
          style={{
            width: '100%',
            height: 175, // Increased from 130 to 180
            objectFit: 'cover',
            objectPosition: 'top', // This ensures we show the top of the image
            borderRadius: '5px',
          }}
        />
        <CardContent
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {project.tags.map((tag, index) => (
            <span key={index} className={`tag ${tag.className}`}>
              {tag.name}
            </span>
          ))}
          <Typography
            gutterBottom
            align="left"
            variant="body1"
            className="header"
            component="div"
            paddingTop={1}
          >
            {project.title}
          </Typography>
          <div className="divider" />
          <Typography
            variant="caption"
            color="text.secondary"
            className="description"
            fontWeight={'light'}
          >
            {project.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function Works() {
  // Split projects into two rows
  const topRowProjects = projects.slice(0, 3);
  const bottomRowProjects = projects.slice(3);

  return (
    <div className="works" id="projects">
      <Typography
        gutterBottom
        align="center"
        variant="h3"
        component="div"
        fontWeight={'bold'}
      >
        Projects
      </Typography>

      <div className="cardContainer">
        <div className="top-row">
          {topRowProjects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
        <div className="bottom-row">
          {bottomRowProjects.map((project) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Works;
